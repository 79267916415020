@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&display=swap);
@font-face {font-family: 'Century Gothic';src: url(/static/media/Century-Gothic-Regular.530f3f52.woff2) format('woff2');font-weight: 400;font-style: normal;}
@font-face {font-family: 'Century Gothic';src: url(/static/media/Century-Gothic-Regular-Italic.79d88939.woff2) format('woff2');font-weight: 400;font-style: italic;}
@font-face {font-family: 'Century Gothic';src: url(/static/media/Century-Gothic-Bold.c002c679.woff2) format('woff2');font-weight: 700;font-style: normal;}
@font-face {font-family: 'Century Gothic';src: url(/static/media/Century-Gothic-Bold-Italic.1d18d82a.woff2) format('woff2');font-weight: 700;font-style: italic;}

body {
    font-family: 'Barlow, Arial, sans-serif';
    font-weight: 400;
    background: url(/static/media/ita-background-light.208608dc.jpg);
    background-size: cover;
    margin: 0;
    padding: 0;
    color: #00205B;
}
