@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&display=swap');
@font-face {font-family: 'Century Gothic';src: url('./assets/fonts/Century-Gothic-Regular.woff2') format('woff2');font-weight: 400;font-style: normal;}
@font-face {font-family: 'Century Gothic';src: url('./assets/fonts/Century-Gothic-Regular-Italic.woff2') format('woff2');font-weight: 400;font-style: italic;}
@font-face {font-family: 'Century Gothic';src: url('./assets/fonts/Century-Gothic-Bold.woff2') format('woff2');font-weight: 700;font-style: normal;}
@font-face {font-family: 'Century Gothic';src: url('./assets/fonts/Century-Gothic-Bold-Italic.woff2') format('woff2');font-weight: 700;font-style: italic;}

body {
    font-family: 'Barlow, Arial, sans-serif';
    font-weight: 400;
    background: url('./assets/images/ita-background-light.jpg');
    background-size: cover;
    margin: 0;
    padding: 0;
    color: #00205B;
}